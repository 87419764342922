import axios from 'axios'
import FileSaver from 'file-saver'
import { Loading } from 'element-ui'
import {
  errorNotice
} from "@/utils/NoticeUtil";
const mimeMap = {
  xlsx: 'application/vnd.ms-excel',
  zip: 'application/zip'
}
/**
 * 以流方式进行下载
 * @param {*} url  请求微服务URL 
 */
export function downloadIO(url) {
  var loading = Loading.service({
    lock: true,
    text: '【每天都是一个好心情】正在下载您需要的文件，请稍后！',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  axios({
    method: 'get',
    url: url,
    responseType: 'blob',secure: true,
  }).then(res => {
    loading.close();
    if (res.headers['content-type'] === 'application/octet-stream') {
      var blob = new Blob([res.data])
      FileSaver.saveAs(blob, decodeURI(res.headers['filename']));
    } else {
      console.error(res);
      errorNotice(res.message) 
    }

  }).catch(error => {
    loading.close();
    console.error(error.message);
  })
}
